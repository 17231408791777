import debounce from '../tools/resize';

const menu = document.querySelector('.menu-toggle');
const mobileMenu = document.querySelector('.mobile-menu-wrap');
const header = document.querySelector('.site-header');
const level1MenuItems = document.querySelectorAll('#mobile-navigation ul li.menu-item-has-children');
const level2MenuItems = document.querySelectorAll('#mobile-navigation .menu-item-has-children.level-2');

const level2MenuItemsTest = document.querySelector('.level-2');


const arrowIcon = `<div class="menu-toggle" aria-controls="primary-menu" aria-expanded="false">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>`;

                    const arrowIcon2 = `<h1>hellllooooooooooo</h1>`;

//ATTACH ARROW TO LEVEL 1 ITEMS
level1MenuItems.forEach(el => {

    el.innerHTML += arrowIcon;

})


level2MenuItems.forEach(el => {


    el.innerHTML = arrowIcon2;

})

//ON LEVEL 1 MENU ITEM CLICK DISPLAY CHILD LINKS
level1MenuItems.forEach(el => {

    el.addEventListener('click', () => {

        //DISPLAY SUB MENU
        el.querySelector('.sub-menu').classList.toggle('open');

    })

})

level2MenuItems.forEach(el => {

    el.addEventListener('click', () => {

        //DISPLAY SUB MENU
        el.querySelector('.sub-menu').classList.toggle('open');

    })

})

//ON OPEN MOBILE NAVIGATION 
menu.addEventListener('click', () => {

    //OPEN MOBILE MENU
    mobileMenu.classList.toggle('open')

    //FIX SITE MENU
    header.classList.toggle('open');

    //ACTIVATE ANIMATION
    menu.classList.toggle('open');

})

//ON RESIZE CLOSE MOBILE MENU IF LARGE SCREEN
window.addEventListener('resize', () => {

    if(window.innerWidth > 1354){

        //OPEN MOBILE MENU
       mobileMenu.classList.remove('open')

       //FIX SITE MENU
       header.classList.remove('open');

       //ACTIVATE ANIMATION
       menu.classList.remove('open');

   }

});


//HIDE ON SCROLL TRIGGERS
const scrollContainer = document.querySelector('.mobile-menu-wrap__container')
const elementMobile = document.querySelector('.mobile-navigation')
const sitelogo = document.querySelector('.site-header .site-logo');

function getPosition(element) {
    var xPosition = 0;
    var yPosition = 0;

    while(element) {
        xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition };
}

const hideOnScroll = () => {
    let distance = getPosition(elementMobile);

    if(distance.y < 235){
        sitelogo.classList.add('hide-logo');
    }else{
        if(sitelogo.classList.contains('hide-logo')){
            sitelogo.classList.remove('hide-logo');
        }
    }
}

scrollContainer.addEventListener("scroll", hideOnScroll);

