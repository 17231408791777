import dataStore from '../data-store';

//On initiate, add the classname of the elements you would like to select. 
//This will loop through and add/remove the active class to them
//TO SHOW ALL
//pages.showAll();

export default class ActiveClass {
    constructor(heading) {
       this.heading = heading;
    }
    
    showOne() {
       const htmlEl = document.querySelectorAll(this.heading);
       
       htmlEl.forEach((item, key) => {
          item.addEventListener('click', (el) => { 
            if(item.classList.contains('active')){
                item.classList.remove('active');
            }else{
                htmlEl.forEach(element => {
                    element.classList.contains('active') ? 
                       element.classList.remove('active') : null;
                 });
                 item.classList.add('active');
            }
          });
       });
    }
    showAll() {
        const htmlEl = document.querySelectorAll(this.heading);
        
        htmlEl.forEach((item, key) => {
           item.addEventListener('click', () => { 
              item.classList.contains('active') ? 
                 item.classList.remove('active') :            
                 item.classList.add('active');
           });
        });
     }
     showSpecific(){
      const htmlEl = document.querySelectorAll(this.heading);
        
      htmlEl.forEach((item, key) => {
         item.addEventListener('click', (el) => { 
            htmlEl.forEach(element => {
               if(element.classList.contains('active') && parseInt(element.innerText) !== dataStore.ajaxCurrentPage){
                  element.classList.remove('active');
               }else if(parseInt(element.innerText) === dataStore.ajaxCurrentPage){
                  element.classList.add('active');
               }
            })
         });
      });
     }
     onHoverAddClass() {
      const htmlEl = document.querySelectorAll(this.heading);
      
      htmlEl.forEach((item, key) => {
         item.addEventListener('mouseenter', (el) => { 
            item.classList.add('active');
         });
      });
      htmlEl.forEach((item, key) => {
         item.addEventListener('mouseleave', (el) => { 
            item.classList.remove('active');
         });
      });
   }
 }
