import $ from 'jquery';

const content = document.querySelectorAll('.three-column-block .hide-text-on-mobile');

function checkSlickSizes(){

  let width = $(window).width();

  if (width < 768) {

    /* the viewport is less than 768 pixels wide */
    $('.three-column-block__container.col-4').not('.slick-initialized').slick({
      infinite: false,
      slidesToShow: 1.1,
      slidesToScroll: 1,
      autoplay: false,
      centerMode: false,
      arrows: false,
      dots: false,
    });
  }else{
     $('.three-column-block__container.col-4').filter('.slick-initialized').slick('unslick');
  }

  if (width < 960) {
    content.forEach(item => {
      item.classList.add('hide');
    })
  }else{
    content.forEach(item => {
      item.classList.remove('hide');
    })
  }
}


if(content){
  checkSlickSizes();
  window.addEventListener('resize', checkSlickSizes);
}




