import ActiveClass from '../tools/active-class';
import modifyElements from '../tools/modifyElements';

// import '../../../src/vendors/fancybox-master/dist/jquery.fancybox.min';
import $ from 'jquery';

$('.thumbs-start').fancybox({
	thumbs : {
		autoStart : true,
	},
    animationEffect : 'fade',
    transitionEffect: "fade",
});

//INIT ANIMATION TO PAGE
let wow;
window.addEventListener('load', () => {

    //WOW ANIMATIONS ACTIVATION
     wow = new WOW(
        {
        boxClass:     'anim',      // default
        animateClass: 'animated', // default
        offset:       0,          // default
        mobile:       true,       // default
        live:         true        // default
        }
        )
    wow.init();

});

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

if(isSafari){
    document.querySelector('body').classList.add('safari');
}
if(isIOS){
    document.querySelector('body').classList.add('ios');
}

  
  

//ADD ACTIVE CLASS TO WHAT THE USER HAS SELECTED
const pages = new ActiveClass('.page');
const term = new ActiveClass('.archive-block__terms li a');
const accordion = new ActiveClass('.accordion');
const hotLinks = new ActiveClass('#hot-links-menu .menu-item');

//INITIATE ACTIVE CLASS
pages.showSpecific();
term.onHoverAddClass();
accordion.showAll();
hotLinks.onHoverAddClass();

//REPLACE ALL A TAGS WITH DIV TAGS
const modifyElement = new modifyElements();
modifyElement.replaceAllTags("a[href='#']",'span');


//ANIMATION TO SLIDE DOWN - USEFUL FOR ACCORDIONS
const slideAnim = (elem) => {

    elem.style.height !== '' ? elem.style.height = '' : elem.style.height = `${elem.scrollHeight}px`;
};

const htmlEls = document.querySelectorAll('.accordion');

if(htmlEls){

    htmlEls.forEach((item, key) => {

        item.addEventListener('click', (el) => { 

            slideAnim(item.children[1]);

        });

    });
}


//ADD ID TO ALL ELEMENTS FOR THE HOMEPAGE HERO SO THAT IT CAN LINK TO IT.
if(document.querySelector('.home')){

    let $i = 1; 

    let children = document.querySelector('.flexible-wrap').children;

    children.forEach(element => {

        element.setAttribute('id', `section-${$i}`);
        
        $i++;

    });
}

const tables = document.querySelectorAll('table');
if(tables){
    tables.forEach(table => {
        table.parentElement.classList.add('text-block__has-table-mobile-overflow')
    }) 
}


//Journey page sections js
const journeyBlocks = document.querySelectorAll('.journey .block');
const lastBlock = document.querySelector('.block--double-height');

const journeyBlocksDynamicHeight = () => {

    let width = window.innerWidth;
    console.log('journey-resize', width)

    let lineBox = document.querySelectorAll('.journey .line-test-box');
    let line1 = document.querySelectorAll('.line-1');
    let line2 = document.querySelectorAll('.line-2');

    let blockHeight = journeyBlocks[0].offsetHeight;
    let blockWidth = journeyBlocks[0].offsetWidth;

    let line1Height = line1[0].offsetHeight;
    let line2Height = line2[0].offsetHeight;

    let topHeight = blockHeight / 2;
    let bottomHeight = blockHeight + line2Height + (blockHeight / 2);
    let leftWidth = blockHeight / 2;

    if(width > 768){
        leftWidth = leftWidth - 20;
    }else{
        leftWidth = leftWidth - 40;
    }

    //top 3 boxes
    journeyBlocks[0].style.height = `${blockWidth - 20}px`;
    journeyBlocks[1].style.height = `${blockWidth - 20}px`;
    journeyBlocks[2].style.height = `${blockWidth - 20}px`;

    //middle 4 boxoes
    journeyBlocks[3].style.height = `${blockWidth - 20}px`;
    journeyBlocks[4].style.height = `${blockWidth - 20}px`;
    journeyBlocks[5].style.height = `${blockWidth - 20}px`;
    journeyBlocks[6].style.height = `${blockWidth - 20}px`;

    blockHeight = journeyBlocks[0].offsetHeight;
    blockWidth = journeyBlocks[0].offsetWidth;

    console.log({
        height: journeyBlocks[3].offsetHeight,
        width: journeyBlocks[3].offsetWidth,
        after : ''
    })

    

    line1Height = line1[0].offsetHeight;
    line2Height = line2[0].offsetHeight;

    topHeight = blockHeight / 2;
    bottomHeight = blockHeight + line2Height + (blockHeight / 2);
    leftWidth = blockHeight / 2;

    
    lastBlock.style.height = `${blockWidth + blockWidth / 2}px`;

    //Set padding to lines for space
    line1.forEach(container => {
        container.style.paddingLeft = `${blockWidth / 2}px`;
        container.style.paddingRight = `${blockWidth / 2}px`;
    })
     //Set padding to lines for space
     line2.forEach(container => {
        container.style.paddingLeft = `${blockWidth / 2}px`;
        container.style.paddingRight = `${blockWidth / 2}px`;
    })


    //UPDATE LINE TO FIT CONTENT
    lineBox.forEach(lineInSection => {
        lineInSection.style.paddingTop = `${topHeight}px`;
        lineInSection.style.paddingBottom = `${bottomHeight + topHeight}px`;
        lineInSection.style.paddingLeft = `${leftWidth}px`;
    })
}

if(journeyBlocks.length > 0){
    window.addEventListener('load', journeyBlocksDynamicHeight);
    window.addEventListener('resize', journeyBlocksDynamicHeight);
}


//Pop-up

const popUp = document.querySelector('.pop-up');

if(popUp){
    const exit = popUp.querySelector('.cross-1px');
    
    exit.addEventListener('click', () => {

        popUp.classList.add('hide')

    })
}



const threeImageBlock = document.querySelectorAll('.three-image-block__container');

const changeHeightOfBlock = () => {
    threeImageBlock.forEach(section => {

        let img1Height =  section.querySelector('.three-image-block__img--1 .image').offsetHeight;
        let img2 =  section.querySelector('.three-image-block__img--3 .image');
     
        img2.style.height = `${img1Height}px`;

    })
}

if(threeImageBlock.length > 0){
    window.addEventListener('load', changeHeightOfBlock);
    window.addEventListener('resize', changeHeightOfBlock);
}


const twoColSections = document.querySelectorAll('.two-links-block');
console.log('two-link-block sections')

if(twoColSections){

    console.log('twolinkblocks found')

    twoColSections.forEach(section => {

        const title = section.querySelector('.container .section-heading');
        const btn = section.querySelector('.container .section-btn');

        if(section.classList.contains('two-links-block--variation--1')){

            console.log('Section has leaf var 1')

            if(title && !btn){

                section.classList.add('two-links-block--spacing-js-2');

            }else if(!title && btn){

                console.log('no title and btn')

            }else if(title && btn){

                console.log('title and btn')

            }else{
                
                section.classList.add('two-links-block--spacing-js-1');

            }

        }
    })
}


const formSections = document.querySelectorAll('.fieldset-cf7mls');

if(formSections){

    formSections.forEach(section => {

        const radio = section.querySelector('.agree-before-next input');
        const nextBtn = section.querySelector('.cf7mls_next');

        console.log('radio btn html', radio);
    
        if(radio){
    
            nextBtn.classList.add('hide');

            radio.addEventListener('change', () => {

                console.log('radio btn changed', radio);
        
                radio.checked ? nextBtn.classList.remove('hide') : nextBtn.classList.add('hide');
                radio.checked ? console.log('agree before next is checked!') : console.log('agree before next is NOT checked!')
        
            })
    
        }
    
    })

}

const eventCal = document.querySelector('.tribe-events');
const eventCal2 = document.querySelector('.tribe-events-pg-template');

if(eventCal || eventCal2){

    var body = document.body;

    body.classList.add('site-header__color--primary');

}

const prosPath2 = document.querySelector('.digital-pros');

//PROSPECTUS PAGE
document.addEventListener( 'wpcf7mailsent', function( event ) {

    console.log(event);
    
    console.log('wp7-form-submitted', event.detail.inputs[5].value);

    if(event.detail.inputs[5].value === 'Prospectus Digital'){
        console.log('PROS PATH AFTER', prosPath2.value);
		// Open the pdf link in a new tab.
		window.open(prosPath2.value, '_blank');
    }
    
}, false );

// Date picker on forms
var datepickers = document.querySelectorAll('.walcf7-datepicker');
var picker = [];
for (var i = 0; i < datepickers.length; i++) {
	var datepicker = datepickers[i];

	picker[i] = new Pikaday({
		field: datepickers[i],
	    format: 'D/M/YYYY',
	    toString(date, format) {
	        // you should do formatting based on the passed format,
	        // but we will just return 'D/M/YYYY' for simplicity
	        const day = date.getDate();
	        const month = date.getMonth() + 1;
	        const year = date.getFullYear();
	        return `${day}/${month}/${year}`;
	    },
	    parse(dateString, format) {
	        // dateString is the result of `toString` method
	        const parts = dateString.split('/');
	        const day = parseInt(parts[0], 10);
	        const month = parseInt(parts[1], 10) - 1;
	        const year = parseInt(parts[2], 10);
	        return new Date(year, month, day);
	    }
	});
}
