gsap.registerPlugin(ScrollTrigger);


const applyImgAnimation = () => {

  const images = gsap.utils.toArray('.parallax');

  if(window.innerWidth > 768){
    images.forEach(image => {
  
      gsap.timeline().from(image, {
        y:80,
        scrollTrigger:{
          trigger: image,
          start:'top 95%',
          end:'50% 50%',
          scrub:'0.3',
        }
      })
    })
  }

}

applyImgAnimation();

