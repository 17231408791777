let bodyClassList = document.querySelector('body').classList;

let getPostType = () => {
    if(bodyClassList.contains('post-type-archive-project')){

        return 'project';

    }else if(bodyClassList.contains('post-type-archive-case-studies')){

        return 'case-studies';

    }else{

        return 'post';

    }
}

let isAjaxUsedOnPage = () => {
    if(bodyClassList.contains('blog') || bodyClassList.contains('author') || bodyClassList.contains('post-type-archive-case-studies') || bodyClassList.contains('post-type-archive-project')){

        return true

    }else{

        return false;
        
    }
}

export let data = {
    currentTerm: '',
    postType: getPostType(),
    authorID: rooster_params.authorID,
    ajaxUrl: rooster_params.ajaxurl,
    ajaxAction: 'get_posts',
    ajaxCurrentPage: 1,
    ajaxMaxPages: 0,
    ajaxUsedOnPage: isAjaxUsedOnPage(),
    ajaxLoading: false,
    ie11: false,
    fancyboxIndex: 1
}

export default data;
