const navigationHeader = document.querySelectorAll('.site-header .main-navigation .level-1');
const navigationFooter = document.querySelectorAll('.site-footer .footer-navigation .level-1');
const mobileNavigation = document.querySelectorAll('#mobile-navigation a');
const showMobileOnly = document.querySelectorAll('.show-on-mobile-only a');



const navigationStyles = (navigation) => {

    navigation.forEach(el => {

        let elWithClass = document.querySelector('.current-menu-item.level-1, .current-menu-ancestor.level-1');
    
        el.addEventListener('mouseover', () => {
    
            let elActive = document.querySelector('.show--underline');
    
            if(elActive){
                elActive.classList.remove('show--underline'); 
            }
    
            if(elWithClass){

                elWithClass.classList.add('hide--underline');
    
            }
    
            el.classList.add('show--underline')
        })
    
        el.addEventListener('mouseleave', () => {
    
            el.classList.remove('show--underline');
    
            if(elWithClass){
    
                elWithClass.classList.remove('hide--underline');
    
            }
    
        })
    
    })
}

const accessiblityNavControls = (nav) => {

    let currentElementID = null;

    nav.forEach(el => {

        el.addEventListener('keyup', (event) => {

            var code = event.keyCode || event.which;

            if (code === 9) {  

                if(currentElementID !== el.id){
                 
                    if(currentElementID !== null){
                 
                        let oldSubwrap = document.querySelector(`#${currentElementID} .sub-wrap`)

                        if(oldSubwrap){
                        
                            oldSubwrap.classList.remove('sub-menu-on-hover');

                        }
                    }

                    let ele = el.querySelector('.sub-wrap');    

                    if(ele){

                        ele.classList.add('sub-menu-on-hover'); 

                    }
                }else{

                    let ele = el.querySelector('.sub-wrap');

                    if(ele){

                        ele.classList.add('sub-menu-on-hover'); 

                    }
                } 

                currentElementID = el.id; 

            }
    
        }); 

    })



}

const accessiblityHideTabFor = (nav) => {

    if(nav){

        nav.forEach(link => link.tabIndex = -1)

    }

}


navigationStyles(navigationHeader);
navigationStyles(navigationFooter);

accessiblityNavControls(navigationHeader);
accessiblityHideTabFor(mobileNavigation);
accessiblityHideTabFor(showMobileOnly);



