import './tools/browser';

//POLYFILLS
import './polyfills/foreach';
import './polyfills/append';

//VENDORS
import './vendors/vendors';

//ANIMATION
import './animations/gsap';

// document.addEventListener('DOMContentLoaded', () => {
//     //Ajust the leafs dependant on te page size. 
//     const singleLeaf1 = document.querySelector('.leaf-1-single');
//     const singleLeaf2 = document.querySelector('.leaf-2-single');
//     const singleLeaf3 = document.querySelector('.leaf-3-single');
//     const singleLeaf4 = document.querySelector('.leaf-4-single');

//     console.log('LEAF PROCESS WORKING')

//     if(singleLeaf1 && singleLeaf2){


//     console.log('has PROCESS WORKING')
        
//         var body = document.body,
//         html = document.documentElement;

//         var height = Math.max( body.scrollHeight, body.offsetHeight, 
//                         html.clientHeight, html.scrollHeight, html.offsetHeight );

//         if(height < 3000){
//             singleLeaf1.classList.add('adjust-pos-height');
//             singleLeaf2.classList.add('adjust-pos-height');
        
//             singleLeaf3.classList.add('hide');
//             singleLeaf4.classList.add('hide');
//         }
//     }  
// })

//VARIABLES
import './data-store';

//TOOLS
import './tools/active-class';
import './tools/resize';
import './tools/modifyElements';
import './tools/hover-intent';

//MINI FUNCTIONS
import './components/mobile-menu';
import './components/navigation';

//AJAX GET POSTS
import './components/get-posts/get-posts';

//CAROUSEL
import './components/carousel/components/progress-bar';
import './components/carousel/carousel';

//MISC (SMALLER FUNCTIONS COMBINED INTO ONE FILE TO SAVE BULD TIME)
import './components/components';
