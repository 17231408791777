import $ from 'jquery';

$('.menu-item.level-1').hoverIntent({
	over: function() {
        
		$(this).addClass('js--hover');

	},
	out: function() {

		$(this).removeClass('js--hover');

	},
});



$(".fancybox").fancybox({
	type: 'iframe',
	width: 900,
	animationEffect: "fade",
	animationDuration: 0,
	width: 500,
	height: 500,
	nextSpeed: 0, //important
	prevSpeed: 0, //important
	beforeShow: function () {
		var newWidth = 900; // set new image display width
		$('.fancybox-iframe').contents().find('img').css({
			width  : newWidth,
			height : "auto"
		}); // apply new size to img
	}
});