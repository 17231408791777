//Swapping all a tags with # set as href into button
export default class modifyElements {

   replaceAllTags(targetElement, newElement) {

        const htmlEl = document.querySelectorAll(targetElement);
        
        htmlEl.forEach( (item) => {

         let newEl = document.createElement(newElement);

          newEl.innerHTML = item.innerHTML;

          item.classList.forEach((data) => {

               newEl.classList.add(data);

          })

          item.parentNode.replaceChild(newEl, item);
           
        });
     }
 }
